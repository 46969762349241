import { LOGIN_URI, LOGOUT_URI, storageKeys } from "@ap/constants";

export const useAuth = () => {
  const clearAuth = () => {
    localStorage.removeItem(storageKeys.TOKEN_ID);
    localStorage.removeItem(storageKeys.ACCESS_TOKEN_ID);
  };

  const logout = () => {
    clearAuth();
    window.location.href = LOGOUT_URI;
  };

  const login = (saveLocation: boolean = false) => {
    if (saveLocation) {
      const currentLocation = `${window.location.pathname}${window.location.search}`;
      localStorage.setItem(storageKeys.REDIRECT_LOCATION, currentLocation);
    }

    clearAuth();
    window.location.href = LOGIN_URI;
  };

  return {
    logout,
    login
  };
};
