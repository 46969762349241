import React from "react";
import { Typography } from "antd";
import { ILocalConsent } from "@ap/interfaces";
import ConsentNotFound from "./ConsentNotFound";
import ConsentNotCaptured from "./ConsentNotCaptured";
import Consent from "./Consent";
import { ISapAccount } from "@msd-cex/sap-cdc-shared";
import "./ConsentSection.scss";

const { Title } = Typography;

interface IConsentSectionProps {
  title: string;
  consents: ILocalConsent[];
  user: ISapAccount;
  onConsentUpdateClick: (consent: ILocalConsent) => () => void;
  onConsentHistoryClick: (consent: ILocalConsent) => () => void;
}

const ConsentSection: React.FC<IConsentSectionProps> = props => {
  const { title, consents, onConsentUpdateClick, onConsentHistoryClick, user } = props;

  return (
    <div className="ConsentSection">
      <Title level={4}>
        {title}
      </Title>
      {consents.map(consent => {
        if (!consent.channelValue) {
          return (
            <ConsentNotFound
              key={consent.channelType}
              consent={consent}
            />
          );
        }

        if (consent.isShowCapture) {
          return (
            <ConsentNotCaptured
              key={consent.channelType}
              consent={consent}
              onCapture={onConsentUpdateClick(consent)}
            />
          );
        }

        return (
          <Consent
            key={consent.channelType}
            user={user}
            consent={consent}
            onUpdate={onConsentUpdateClick(consent)}
            onShowHistory={onConsentHistoryClick(consent)}
          />
        );
      })}
    </div>
  );
};

export default ConsentSection;
