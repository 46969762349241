import React, { useState } from "react";
import { Input, Form } from "antd";
import clsx from "clsx";
import { ICustomFieldProps } from "../";
import "./CustomPhoneField.scss";

type ICustomPhoneFieldProps = Pick<ICustomFieldProps,
"type" | "name" | "label" | "disabled" | "rules" | "value"
>;

const CustomPhoneField: React.FC<ICustomPhoneFieldProps> = (
  { disabled, name, label, rules, value }
) => {
  const [text, setText] = useState<string>(value ? value as string : "");
  const [plusDisabled, setPlusDisabled] = useState<boolean>(!text);

  const handleFocus = () => {
    setPlusDisabled(false);
  };

  const handleBlur = () => {
    setPlusDisabled(!text);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const regex = /^\d*$/;
    const valid = regex.test(text);

    if (valid) {
      setText(text);
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={["onChange", "onBlur"]}
      validateDebounce={500}
      getValueProps={() => ({
        value: text.startsWith("+") ? text.substring(1) : text
      })}
      normalize={value => {
        return value ? `+${value}` : value;
      }}
    >
      <Input
        size="large"
        disabled={disabled}
        prefix={(
          <span
            className={clsx(
              "CustomPhoneField__Plus",
              plusDisabled && "CustomPhoneField__Plus--disabled"
            )}
          >
            +
          </span>
        )}
        value={text}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default CustomPhoneField;
