import { IOptionItem } from "@ap/interfaces";

export const specialties: IOptionItem[] = [
  { text: "" },
  { text: "Acupuncture" },
  { text: "Adult Cardiothoracic Anesthesiology (Anesthesiology)" },
  { text: "Addiction Medicine" },
  { text: "Adolescent Medicine-Im" },
  { text: "Administrative Medicine" },
  { text: "Aerospace Medicine" },
  { text: "Allergology" },
  { text: "Allergy and Immunology" },
  { text: "Anatomic Path and Lab Medicine" },
  { text: "Anatomic Pathology" },
  { text: "Anesthesiology" },
  { text: "Anesthesiology, Critical Care" },
  { text: "Anesthesiology, Pain Medicine" },
  { text: "Andrology" },
  { text: "Asthma" },
  { text: "Audiology" },
  { text: "Bacteriology" },
  { text: "BioMedical Engineer" },
  { text: "Blood Banking/Transfusion Med" },
  { text: "Cardiac Electrophysiology" },
  { text: "Cardiology" },
  { text: "Cardiology, Interventional" },
  { text: "Cardiology, Pediatric" },
  { text: "Cardiovascular Disease" },
  { text: "Chemical Pathology" },
  { text: "Child Psychiatry-Pediatrics" },
  { text: "Chinese & Western Medicine" },
  { text: "Chiropractic Medicine" },
  { text: "Civil Service Medicine" },
  { text: "Clinical Biochem/Molecular Genetics" },
  { text: "Clinical Biochemical Genetics" },
  { text: "Clinical Informatics - Preventive Medicine" },
  { text: "Clinical Genetics" },
  { text: "Clinical Laboratory Immunology (Allergy and Immunology)" },
  { text: "Clinical Pathology" },
  { text: "Clinical Pharmacology and Pharmaceutical Med" },
  { text: "Congenital Cardiac Surgery, Thoracic Surgery" },
  { text: "Critical Care Medicine-Cardiology" },
  { text: "Critical Care Medicine - EM" },
  { text: "Critical Care Medicine-Im" },
  { text: "Clinical Pharmacology" },
  { text: "Dental Public Health" },
  { text: "Dentistry" },
  { text: "Dermatologic Oncology" },
  { text: "Dermatology" },
  { text: "Diabetes" },
  { text: "Diagnostic Endoscopy" },
  { text: "Dietetics" },
  { text: "Embryology" },
  { text: "Emergency & Critical Care Medicine" },
  { text: "Emergency Medicine" },
  { text: "Emergency Medicine, Pediatrics" },
  { text: "Endocrinology" },
  { text: "Endocrinology, Diabetes and Metabolism" },
  { text: "Endodontics" },
  { text: "Epidemiology" },
  { text: "Esthetics/Cosmetology" },
  { text: "Family Medicine" },
  { text: "Family Medicine/General Practice" },
  { text: "Family Medicine/Preventative Medicine" },
  { text: "Family Planning" },
  { text: "Family Practice, Geriatric Medicine" },
  { text: "Forensic Pathology" },
  { text: "Gastroenterology" },
  { text: "General Practice" },
  { text: "General Preventive Medicine" },
  { text: "Genetics" },
  { text: "Genitourinary Medicine" },
  { text: "GERIATRICS/GERONTOLOGY" },
  { text: "Gynecology" },
  { text: "Hepato-Biliary-Pancreatic Surgery" },
  { text: "Hematology (Pathology)" },
  { text: "Hematology and Oncology" },
  { text: "Hematology-IM" },
  { text: "Hepatology" },
  { text: "Homeopathy" },
  { text: "Hospice & Palliative Medicine" },
  { text: "Hospital Pediatrics" },
  { text: "Hospitalist" },
  { text: "HIV-AIDS Medicine" },
  { text: "Immunology" },
  { text: "Immunology/Vaccination" },
  { text: "Infectious Diseases/Internal Med" },
  { text: "Internal Medicine" },
  { text: "Internal Medicine, Family Practice" },
  { text: "Internal Medicine, Geriatrics" },
  { text: "Infectious Disease" },
  { text: "Kinesiology" },
  { text: "Laboratory Medicine" },
  { text: "Legal Medicine" },
  { text: "Lipidology" },
  { text: "Maternal and Fetal Medicine" },
  { text: "Medical Biochemical Genetics" },
  { text: "Medical Biologics" },
  { text: "Medical Genetics" },
  { text: "Medical Management" },
  { text: "Medical Management Administration" },
  { text: "Medical Management Purchasing" },
  { text: "Medical Microbiology" },
  { text: "Medical Physics" },
  { text: "Medical Toxicology" },
  { text: "Microbiology/Virology" },
  { text: "Midwifery" },
  { text: "Neonatal-Perinatal Medicine" },
  { text: "Neonatology" },
  { text: "Nephrology" },
  { text: "Neurological Surgery" },
  { text: "Neurology" },
  { text: "Neurology, Child" },
  { text: "Neurology, Clinical Neurophysiology" },
  { text: "Neurology/Diagnostic Radiology/Neuroradiology" },
  { text: "Neuromuscular Medicine, Physical Medicine & Rehabilitation" },
  { text: "Neuromusculoskeletal Medicine" },
  { text: "Neuropathology" },
  { text: "Neuropsychiatry" },
  { text: "Neuropsychology" },
  { text: "Nuclear Medicine" },
  { text: "Nursing" },
  { text: "Nutrition" },
  { text: "Obstetrics" },
  { text: "Obstetrics and Gynecology" },
  { text: "Occupational & Environmental Medicine" },
  { text: "Occupational Medicine" },
  { text: "Occupational Therapy" },
  { text: "Oncology, Medical" },
  { text: "Oncology" },
  { text: "Oncology Neuro- Endo" },
  { text: "Oncology, Breast" },
  { text: "Oncology, Gynecological" },
  { text: "Ophthalmology" },
  { text: "Optometry" },
  { text: "Oral Surgery" },
  { text: "Oral & Maxillofacial Surgery (Dentistry)" },
  { text: "Orthodontics" },
  { text: "Orthopedics" },
  { text: "Orthopedics, Adult Reconstructive" },
  { text: "Orthopedics/Orthopedic Surgery" },
  { text: "Orthopedic Pediatrics" },
  { text: "Orthopedic Surgery" },
  { text: "Orthopedic Surgery Of The Spine" },
  { text: "Osteopathic Manipulative Medicine" },
  { text: "Other Specialty" },
  { text: "Otolaryngology" },
  { text: "Pain Management" },
  { text: "Pain Medicine" },
  { text: "Pain Medicine (Neurology)" },
  { text: "Palliative Medicine" },
  { text: "Pathology" },
  { text: "Pathology, Anatomic/Clinical" },
  { text: "Pathology, Immunopathalogy" },
  { text: "Pediatric Allergy and Immunology" },
  { text: "Pediatric Hematology, Oncology" },
  { text: "Pediatric Nephrology" },
  { text: "Pediatric Neurology" },
  { text: "Pediatric Rheumatology" },
  { text: "Pediatrics" },
  { text: "Pediatric Allergy" },
  { text: "Pediatric Endocrinology" },
  { text: "Pediatric Infectious Disease" },
  { text: "Pediatric Gastroenterology" },
  { text: "Pediatric Pulmonary Disease" },
  { text: "Pediatric Surgery" },
  { text: "Pediatrics, Adolescent Medicine" },
  { text: "Pediatrics, Critical Care" },
  { text: "Pediatrics, Developmental-Behavioral" },
  { text: "Periodontics" },
  { text: "Pharmaceutical Medicine" },
  { text: "Pharmacy" },
  { text: "Pharmacy-Hospital Pharmacist" },
  { text: "Physician Executive" },
  { text: "Physical Medicine and Rehab" },
  { text: "Physical Therapy/Physiotherapy" },
  { text: "Physiology" },
  { text: "Plastic and Reconstructive Surgery" },
  { text: "Plastic Surgery" },
  { text: "Podiatry" },
  { text: "Preventive Medicine & Public Health" },
  { text: "Preventive, Occupational and Environmental Med" },
  { text: "Proctology" },
  { text: "Psychiatry" },
  { text: "Psychiatry, Child and Adolescent" },
  { text: "Psychiatry, Geriatric" },
  { text: "Psychiatry, Forensic" },
  { text: "Psychoanalysis" },
  { text: "Psychology" },
  { text: "Psychosomatic Medicine" },
  { text: "Public Health" },
  { text: "Public Health and General Preventive Med" },
  { text: "Pulmonary Critical Care Medicine" },
  { text: "Pulmonary Disease" },
  { text: "Pulmonary Medicine" },
  { text: "Pulmonology" },
  { text: "Radiation Oncology" },
  { text: "Radiological Physics" },
  { text: "Radiology" },
  { text: "Radiology, Diagnostic" },
  { text: "Rehabilitation Medicine" },
  { text: "Reproductive Endocrinology and Infertility" },
  { text: "Reproductive Medicine (APNs)" },
  { text: "Research Medicine" },
  { text: "Respiratory Care Therapy" },
  { text: "Rheumatology" },
  { text: "Rural & Community Health (APNs)" },
  { text: "School Health Care" },
  { text: "Sexology" },
  { text: "Sleep Medicine" },
  { text: "Sleep Medicine (Anesthesiology)" },
  { text: "Social Work" },
  { text: "Speech-Language Pathology" },
  { text: "Sports Medicine" },
  { text: "Sports Medicine, Physical Med and Rehab" },
  { text: "Stomatology" },
  { text: "Surgery" },
  { text: "Surgery, Cardiothoracic" },
  { text: "Surgery, Cardiovascular" },
  { text: "Surgery, Colon and Rectal" },
  { text: "Surgery, Cosmetic" },
  { text: "Surgery, Critical Care" },
  { text: "Surgery, Endocrinology" },
  { text: "Surgery, Gastroenterology" },
  { text: "Surgery, General" },
  { text: "Surgery, Head and Neck" },
  { text: "Surgery, Oral and Maxillofacial" },
  { text: "Surgery, Thoracic" },
  { text: "Surgery, Thoracic Cardiovascular" },
  { text: "Surgery, Transplant" },
  { text: "Surgery, Urological" },
  { text: "Surgery, Vascular" },
  { text: "Surgical Oncology" },
  { text: "Therapeutic Radiology" },
  { text: "Toxicology" },
  { text: "Traditional Chinese Medicine" },
  { text: "Transitional Year" },
  { text: "Transplant Medicine" },
  { text: "Traumatology" },
  { text: "Tropical Medicine" },
  { text: "Undersea and Hyperbaric Medicine - Family Prac" },
  { text: "Unspecified" },
  { text: "Urogynecology" },
  { text: "Urology" },
  { text: "Vascular Medicine" },
  { text: "Venereology" },
  { text: "Veterinary Medicine" },
  { text: "Virology" },
  { text: "Visceral Surgery" },
  { text: "Women's Health (APNs)" }
];
