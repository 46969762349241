import { AxiosRequestConfig } from "axios";
import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { API_URL } from "../apiUrl";

export interface IGetUsersRequest {
  data: {
    countryCode: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    filter?: {
      "quickSearch": {
        regex: string[];
      };
    };
  };
}

export interface IGetUsersResponse {
  data: ISapAccount[];
}

export const getUsers = ({ data }: IGetUsersRequest): AxiosRequestConfig => {
  return {
    url: API_URL.USERS,
    method: "POST",
    data: data
  };
};
