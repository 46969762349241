import { routes } from "./common/routes";

export const storageKeys = {
  TOKEN_ID: "TOKEN_ID",
  ACCESS_TOKEN_ID: "ACCESS_TOKEN_ID",
  SELECTED_FLOW_ID: "SELECTED_FLOW_ID",
  REDIRECT_LOCATION: "REDIRECT_LOCATION"
};

if (!process.env.AWS_COGNITO_USER_POOL_DOMAIN) {
  throw Error("AWS_COGNITO_USER_POOL_DOMAIN should be defined");
}

if (!process.env.AWS_COGNITO_APP_CLIENT_ID) {
  throw Error("AWS_COGNITO_APP_CLIENT_ID should be defined");
}

export const COGNITO_DOMAIN = process.env.AWS_COGNITO_USER_POOL_DOMAIN.endsWith("/")
  ? process.env.AWS_COGNITO_USER_POOL_DOMAIN.slice(0, -1)
  : process.env.AWS_COGNITO_USER_POOL_DOMAIN;

export const COGNITO_CLIENT_ID = process.env.AWS_COGNITO_APP_CLIENT_ID;

export const REDIRECT_URI = `${location.origin}/${routes.loginStatus}`;

const loginSearchParams = new URLSearchParams({
  client_id: COGNITO_CLIENT_ID,
  response_type: "code",
  scope: "aws.cognito.signin.user.admin+email+openid+phone+profile",
  redirect_uri: REDIRECT_URI
});
const logoutSearchParams = new URLSearchParams({
  client_id: COGNITO_CLIENT_ID,
  logout_uri: window.location.origin
});

const loginUrl = new URL(`${COGNITO_DOMAIN}/oauth2/authorize`);
loginUrl.search = decodeURIComponent(loginSearchParams.toString());

const logoutUrl = new URL(`${COGNITO_DOMAIN}/logout`);
logoutUrl.search = decodeURIComponent(logoutSearchParams.toString());

export const LOGIN_URI = loginUrl.toString();
export const LOGOUT_URI = logoutUrl.toString();

export const CONSENT_SOURCE_ID = process.env.CONSENT_SOURCE_ID ?? "";
export const CONSENT_SOURCE = "CDC";
export const CONSENT_UPDATED_BY = "admin-ui";

export const permissions = {
  PROFILE_UPDATE: "profile:update",
  CONSENT_CAPTURE: "consent:capture",
  CONSENT_UPDATE: "consent:update",
  CONSENT_HISTORY: "consent:history"
};

export const phoneTypes = {
  PHONE: "phone",
  SMS: "sms",
  FAX: "fax"
};
