import React from "react";
import { Select, Form } from "antd";
import { ICustomFieldProps } from "../";

type ICustomSelectFieldProps = Pick<ICustomFieldProps,
"name" | "label" | "disabled" | "options" | "rules"
>;

const { Option } = Select;

const CustomSelectField: React.FC<ICustomSelectFieldProps> = (
  { disabled, options, name, label, rules }
) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={["onChange", "onBlur"]}
      validateDebounce={500}
      getValueProps={value => ({
        value: value ?? ""
      })}
    >
      <Select size="large" disabled={disabled}>
        {options?.map(option => {
          const value = option.value ?? option.text;
          const text = option.text;

          return (
            <Option key={value} value={value}>
              {text}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default CustomSelectField;
