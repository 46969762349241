import { FC, PropsWithChildren, useMemo, useState } from "react";
import { IPermissions, IPermissionsFlow } from "@ap/interfaces";
import AppContext from "./AppContext";

interface IAppContextProviderProps extends PropsWithChildren {
}

const AppContextProvider: FC<IAppContextProviderProps> = props => {
  const [permissions, setPermissions] = useState<IPermissions | null>(null);
  const [activeFlow, setActiveFlow] = useState<IPermissionsFlow | null>(null);

  const value = useMemo(() => ({
    permissions,
    activeFlow,
    setPermissions,
    setActiveFlow
  }), [permissions, activeFlow]);

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
