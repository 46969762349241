import { IOptionItem } from "@ap/interfaces";

export const professions: IOptionItem[] = [
  { text: "" },
  { text: "Administrator" },
  { text: "Clinical Pharmacist" },
  { text: "Dentist" },
  { text: "Dietitian" },
  { text: "Executive" },
  { text: "General Practitioner" },
  { text: "Government" },
  { text: "Health Care Advisor" },
  { text: "Health Care Practitioner" },
  { text: "Health Manager" },
  { text: "Healthcare Professional Other" },
  { text: "Hospital Pharmacist" },
  { text: "Hospital Physician" },
  { text: "Hospital/Practice Physician" },
  { text: "Journalist" },
  { text: "Lab Technician" },
  { text: "Medical Assistant" },
  { text: "Medical Student" },
  { text: "Merck Employee" },
  { text: "Microbiologist" },
  { text: "Midwife" },
  { text: "Non-Medical Healthcare Worker" },
  { text: "Nurse" },
  { text: "Office Manager" },
  { text: "Paramedical" },
  { text: "Pharmacist" },
  { text: "Pharmacist Assistant" },
  { text: "Pharmacy Manager" },
  { text: "Pharmacy Tech" },
  { text: "Physical Therapist" },
  { text: "Physician" },
  { text: "Researcher" },
  { text: "Specialist Physician" },
  { text: "State Pharmacist" },
  { text: "State Physician" },
  { text: "Veterinarian" }
];
