import * as React from "react";
import { IPermissions, IPermissionsFlow } from "@ap/interfaces";

export interface IAppContextProvider {
  permissions: IPermissions | null;
  activeFlow: IPermissionsFlow | null;
  setPermissions: (permissions: IPermissions | null) => void;
  setActiveFlow: (permissionsFlow: IPermissionsFlow | null) => void;
}

export const AppContext = React.createContext<IAppContextProvider>({} as IAppContextProvider);

export default AppContext;
