import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { message, Col, Form, FormProps, Row, Typography, Button } from "antd";
import { get } from "lodash";
import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { mappedUserFields } from "@ap/common/userFields";
import { routes } from "@ap/common/routes";
import { flattenObjectKeys } from "@ap/utils";
import { useFetch } from "@ap/hooks";
import * as api from "@ap/services/api";
import Loader from "@ap/components/Loader";
import CustomField from "@ap/components/CustomField";
import { IApiResponse } from "@ap/interfaces";
import { getSubmitData } from "./UserEdit.utils";
import "./UserEdit.scss";

const { Title } = Typography;

interface IUserEditProps {
  user: ISapAccount;
}

const UserEdit: React.FC<IUserEditProps> = ({ user }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { uid } = useParams();
  const [updating, setUpdating] = useState<boolean>(false);
  const updateUser = useFetch<api.IUpdateUserRequest>(api.updateUser);
  const [updateUserError, setUpdateUserError] = useState<string>("");

  const userRoute = `/${routes.users}/${uid}`;

  const fetchUpdateUser = async(request: api.IUpdateUserRequest) => {
    setUpdating(true);

    const handleError = (error: unknown) => {
      console.error("Unable to update user:", error);
      setUpdateUserError(`${error}`);
    };

    try {
      const response = await updateUser<api.IUpdateUserResponse>(request);
      const responseBody = JSON.parse(response.data.body) as IApiResponse;

      if (responseBody.error) {
        if (responseBody.error.includes("Schema validation failed")) {
          handleError("Unable to update customer's account. Please, contact support team.");
        } else {
          handleError(`User saving failed: ${responseBody.error}`);
        }

        return;
      }

      navigate(userRoute, { state: location.state });
    } catch (error) {
      handleError(error);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (updateUserError) {
      messageApi.open({
        type: "error",
        content: `User saving failed: ${updateUserError}`
      }).then(() => {
        setUpdateUserError("");
      });
    }
  }, [updateUserError]);

  const formData = flattenObjectKeys(user);

  const onFinish: FormProps["onFinish"] = updatedValues => {
    const submitData = getSubmitData(user, formData, updatedValues);

    if (Object.keys(submitData).length === 0) {
      console.log("Form has not changed. Nothing to submit");
      return;
    }

    const uid = get(formData, "profile.uid");

    if (!uid) {
      console.error("Unable to find uid");
      return;
    }

    void fetchUpdateUser({
      params: {
        uid: uid
      },
      data: submitData
    });
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    console.log("Failed:", errorInfo);

    if (errorInfo.errorFields.length > 0) {
      void messageApi.open({
        type: "error",
        content: "Form validation failed"
      });
    }
  };

  return (
    <div className="edit__wrapper">
      {contextHolder}

      <Form
        form={form}
        layout="vertical"
        initialValues={formData}
        autoComplete="off"
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center"
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <header className="edit__header">
          <h2 className="edit__title">Edit Profile</h2>
          <div className="edit__actions">
            <Link to={userRoute} state={location.state}>
              <Button size="large">
                Cancel
              </Button>
            </Link>

            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
              >
                Save changes
              </Button>
            </Form.Item>
          </div>
        </header>

        <div className="edit__form">
          {mappedUserFields.map(({ group, fields }) => (
            <div className="edit__content" key={group}>
              <Title level={4}>{group}</Title>
              <Row gutter={[16, 16]}>
                {fields.map(field => (
                  <Col key={field.name} span={8}>
                    <CustomField
                      {...field }
                      value={field.mapper ? field.mapper(user) : get(formData, field.name)}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </div>
      </Form>

      {updating && (
        <Loader modal>
          Saving user data ...
        </Loader>
      )}
    </div>
  );
};

export default UserEdit;
