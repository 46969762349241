import { SetURLSearchParams, useSearchParams } from "react-router-dom";

type IUseQueryReturn = () => [Record<string, string>, SetURLSearchParams];

export const useQueryParams: IUseQueryReturn = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(search)
  );

  return [searchAsObject, setSearch];
};
