import React, { useState, useEffect, useContext } from "react";
import { Drawer, Button, FormProps, Form, Input, Select, Checkbox, message, Typography } from "antd";
import type { CheckboxProps } from "antd";
import { ILocalConsent, IConsentUpdate, ChannelTypes } from "@ap/interfaces";
import * as api from "@ap/services/api";
import { useFetch } from "@ap/hooks";
import Loader from "@ap/components/Loader";
import { AppContext } from "@ap/store";
import { CONSENT_SOURCE_ID, CONSENT_SOURCE, CONSENT_UPDATED_BY } from "@ap/constants";
import "./ConsentUpdatePopup.scss";

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

interface IConsentUpdateProps {
  isCapture: boolean;
  isOpen: boolean;
  mdmId: string;
  consent: ILocalConsent;
  countryCode: string;
  onClose: () => void;
  onConsentUpdated: () => void;
}

const ConsentUpdatePopup: React.FC<IConsentUpdateProps> = props => {
  const { consent } = props;
  const consentOptIn = consent.optIn ?? false;
  const appContext = useContext(AppContext);
  const userEmail = appContext.permissions?.email;
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [optIn, setOptIn] = useState<boolean>(consentOptIn);
  const [updating, setUpdating] = useState<boolean>(false);
  const updateConsent = useFetch<api.IUpdateConsentRequest>(api.updateConsent);
  const [updateConsentError, setUpdateConsentError] = useState<string>("");
  const [updateConsentMessage, setUpdateConsentMessage] = useState<string>("");

  const handlePopupClose = () => {
    props.onClose();
  };

  const fetchUpdateConsent = async(request: api.IUpdateConsentRequest) => {
    setUpdating(true);

    const handleError = (error: unknown) => {
      console.error("Unable to update consent:", error);
      setUpdateConsentError(`${error}`);
    };

    try {
      const response = await updateConsent<api.IUpdateConsentResponse>(request);
      const errorMessage = response.data.error;

      if (errorMessage) {
        handleError(`Consent saving failed: ${errorMessage}`);
        return;
      }

      setUpdateConsentMessage("Consent successfully saved");
      handlePopupClose();
      props.onConsentUpdated();
    } catch (error) {
      handleError(error);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (updateConsentError) {
      messageApi.open({
        type: "error",
        content: `Consent saving failed: ${updateConsentError}`
      }).then(() => {
        setUpdateConsentError("");
      });
    }
  }, [updateConsentError]);

  useEffect(() => {
    if (updateConsentMessage) {
      messageApi.open({
        type: "success",
        content: `Consent successfully saved: ${updateConsentMessage}`
      }).then(() => {
        setUpdateConsentMessage("");
      });
    }
  }, [updateConsentMessage]);

  useEffect(() => {
    const submitDisabled = (optIn === consentOptIn);

    setSubmitDisabled(submitDisabled);
  }, [optIn]);

  useEffect(() => {
    if (props.isOpen) {
      setOptIn(consentOptIn);
    } else {
      form.resetFields();
    }
  }, [props.isOpen]);

  const onFinish: FormProps["onFinish"] = formValues => {
    if (!consent?.channelValue) {
      return;
    }

    const consentUpdateData: IConsentUpdate = {
      channelType: consent.channelType,
      channelValue: consent.channelValue,
      optIn: optIn,
      timestamp: new Date().toISOString(),
      source: CONSENT_SOURCE,
      sourceId: CONSENT_SOURCE_ID,
      updatedBy: CONSENT_UPDATED_BY,
      agentId: userEmail,
      changeReasonDocument: formValues.changeReasonDocument,
      changeReasonType: formValues.changeReasonType,
      comment: formValues.comment ?? "",
      consentSource: consent.consentSource ?? "",
      consentSourceKey: consent.consentSourceKey ?? "",
      disclaimer: consent.disclaimer ?? "",
      reason: consent.reason ?? "",
      referral: consent.referral
    };

    void fetchUpdateConsent({
      data: {
        mdmId: props.mdmId,
        countryCode: props.countryCode,
        consents: [consentUpdateData]
      }
    });
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    console.log("Failed:", errorInfo);

    if (errorInfo.errorFields.length > 0) {
      void messageApi.open({
        type: "error",
        content: "Form validation failed"
      });
    }
  };

  const handleCheckboxChange: CheckboxProps["onChange"] = () => {
    setOptIn(!optIn);
  };

  const renderDisclaimer = () => {
    switch (consent.channelType) {
      case ChannelTypes.EMAIL: {
        return (
          <span>
            Ich möchte E-Mail-Mitteilungen von MSD erhalten, z.B.
            Updates zu pharmazeutischen Produkten (Arzneimitteln und Impfstoffen),
            Einladungen zu medizinischen Veranstaltungen, Schulungen und Online-Events.
          </span>
        );
      }
      case ChannelTypes.PHONE: {
        return (
          <span>
            Ich möchte telefonisch zu Produkten und Angeboten von MSD kontaktiert werden.
          </span>
        );
      }
      default: {
        return (
          <span>
          </span>
        );
      }
    }
  };

  const actionTitle = props.isCapture ? "Capture Consent" : "Update Consent";
  const title = `${actionTitle} - ${consent.title} - ${consent.channelValue}`;

  return (
    <Drawer
      size="large"
      className="ConsentUpdatePopup"
      title={title}
      open={props.isOpen}
      onClose={props.onClose}
    >
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        autoComplete="off"
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center"
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Title level={4}>
          {consent.title}
        </Title>

        {props.isCapture && (
          <p className="ConsentUpdatePopup__Disclaimer">
            {renderDisclaimer()}
          </p>
        )}

        <Form.Item>
          <Checkbox checked={optIn} onChange={handleCheckboxChange}>
            {consent.channelValue}
          </Checkbox>
        </Form.Item>
        <Form.Item name="changeReasonType" label="Change reason type" rules={[{ required: true }]}>
          <Select placeholder="Select..." allowClear>
            {["Telephone", "Face-To-Face", "Letter", "Email", "Technical"].map(option => (
              <Option key={option} value={option}>{option}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="changeReasonDocument" label="Change reason document" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="comment" label="Comment">
          <TextArea rows={3} placeholder="" maxLength={180} />
        </Form.Item>
        <Form.Item>
          <div className="ConsentUpdatePopup__Footer">
            <Button onClick={handlePopupClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" disabled={submitDisabled}>
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

      {updating && (
        <Loader modal>
          Updating consent data ...
        </Loader>
      )}
    </Drawer>
  );
};

export default ConsentUpdatePopup;
