import { isPlainObject } from "lodash";

export const flattenObjectKeys = (
  source: object,
  parentKey = "",
  output: object = {}
): object => {
  if (!isPlainObject(source)) {
    return source;
  }

  return Object.entries(source).reduce((result, [key, value]) => {
    const outputKey = parentKey ? `${parentKey}.${key}` : key;
    const outputValue = flattenObjectKeys(value, outputKey, output);

    if (!isPlainObject(outputValue)) {
      (result as Record<string, unknown>)[outputKey] = outputValue;
    }

    return result;
  }, output);
};
