import { get } from "lodash";
import type { IPhone } from "@msd-cex/sap-cdc-shared";

export const getUserPhones = (phones?: IPhone[] | Record<string, string>): IPhone[] => {
  if (!phones) {
    return [];
  }

  if (Array.isArray(phones)) {
    return phones;
  }

  return [{
    type: get(phones, "type"),
    number: get(phones, "number")
  }];
};
