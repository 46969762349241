import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryParams, useFetch, useAuth } from "@ap/hooks";
import { storageKeys } from "@ap/constants";
import { routes } from "@ap/common/routes";
import Loader from "@ap/components/Loader";
import * as api from "@ap/services/api";

const LoginStatusPage: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [{ code }] = useQueryParams();
  const [loading, setLoading] = useState<boolean>(true);
  const getLoginStatus = useFetch<api.IGetLoginStatusRequest>(api.getLoginStatus, false);

  const fetchLoginStatus = async() => {
    setLoading(true);

    const handleError = (error: unknown) => {
      console.error("Unable to load login status:", error);
    };

    try {
      const getLoginStatusResponse = await getLoginStatus<api.IGetLoginStatusResponse>({
        params: {
          authCode: code
        }
      });

      if (!getLoginStatusResponse.id_token) {
        handleError("Failed to retrieve token");
      }

      localStorage.setItem(storageKeys.TOKEN_ID, getLoginStatusResponse.id_token);
      localStorage.setItem(storageKeys.ACCESS_TOKEN_ID, getLoginStatusResponse.access_token);

      const redirectLocation = localStorage.getItem(storageKeys.REDIRECT_LOCATION);

      if (redirectLocation) {
        localStorage.removeItem(storageKeys.REDIRECT_LOCATION);
        navigate(redirectLocation);
      } else {
        navigate(`/${routes.users}`);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!code) {
      login();
      return;
    }

    void fetchLoginStatus();
  }, []);

  if (loading) {
    return (
      <Loader>
        Loading login status ...
      </Loader>
    );
  }

  return (
    <>
      Redirecting to users page ...
    </>
  );
};

export default LoginStatusPage;
