import React from "react";
import { createPortal } from "react-dom";
import { Spin } from "antd";
import "./Loader.scss";

export interface ILoaderProps extends React.PropsWithChildren {
  modal?: boolean;
}

const Loader: React.FC<ILoaderProps> = props => {
  const content = (
    <div className="Loader">
      <Spin size="large" />

      {props.children && (
        <div>
          {props.children}
        </div>
      )}
    </div>
  );

  return props.modal ? (
    createPortal(
      <div className="Loader__Background">
        {content}
      </div>,
      document.body
    )
  ) : (
    content
  );
};

export default Loader;
