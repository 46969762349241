import React, { useState, useCallback } from "react";
import { DatePicker, DatePickerProps, Form } from "antd";
import * as dayjs from "dayjs";
import { formatDateString, formatDateTimeString } from "@ap/utils";
import { ICustomFieldProps } from "../";

type ICustomDateFieldProps = Pick<ICustomFieldProps,
"type" | "name" | "label" | "disabled" | "rules" | "value"
>;

const minDate = new Date(0);

const CustomDateField: React.FC<ICustomDateFieldProps> = (
  { type, disabled, name, label, rules, value }
) => {
  const initialDate = value ? dayjs(String(value)) : dayjs(minDate);
  const initialDisplayDate = value ? dayjs(String(value)) : dayjs();

  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(initialDate);
  const [displayDate, setDisplayDate] = useState<dayjs.Dayjs>(initialDisplayDate);

  const handleDateChange = useCallback(
    (date: dayjs.Dayjs) => {
      setSelectedDate(date || dayjs(minDate));
      setDisplayDate(date || initialDisplayDate);
    },
    [initialDisplayDate]
  );

  const formatDate: DatePickerProps["format"] = (value: dayjs.Dayjs) => {
    const date = value.toDate();

    if (date.valueOf() === minDate.valueOf()) {
      return "";
    }

    if (type === "datetime") {
      return formatDateTimeString(date);
    } else {
      return formatDateString(date);
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={["onChange", "onBlur"]}
      validateDebounce={500}
      getValueProps={value => ({
        value: dayjs(value || minDate)
      })}
      normalize={value => {
        return value
          ? formatDateTimeString((value as dayjs.Dayjs).toDate())
          : "";
      }}
    >
      <DatePicker
        size="large"
        disabled={disabled}
        format={formatDate}
        placeholder={disabled ? "" : undefined}
        defaultPickerValue={displayDate}
        allowClear={selectedDate.toDate().valueOf() !== minDate.valueOf()}
        onChange={handleDateChange}
      />
    </Form.Item>
  );
};

export default CustomDateField;
