export const routes = {
  users: "users",
  login: "login",
  loginStatus: "login-status",
  logout: "logout",
  logoutSuccess: "logout-success",
  userDetails: {
    consent: "consent",
    edit: "edit",
    profile: ""
  }
};
