import React from "react";
import { Input, Form } from "antd";
import { ICustomFieldProps } from "../";

type ICustomInputFieldProps = Pick<ICustomFieldProps,
"type" | "name" | "label" | "disabled" | "rules" | "value" | "placeholder"
>;

const CustomInputField: React.FC<ICustomInputFieldProps> = (
  { type, placeholder, disabled, name, label, rules }
) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={["onChange", "onBlur"]}
      validateDebounce={500}
    >
      <Input
        size="large"
        type={type}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default CustomInputField;
