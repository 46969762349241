import * as React from "react";
import { Drawer, Collapse } from "antd";
import { IConsent, ILocalConsent } from "@ap/interfaces";
import { formatDateTimeString } from "@ap/utils";
import "./ConsentHistoryPopup.scss";

interface IConsentHistoryProps {
  open: boolean;
  mdmId: string;
  consent: ILocalConsent;
  onClose: () => void;
}

interface IConsentHistoryItem {
  label: string;
  text?: string;
}

const ConsentHistoryPopup: React.FC<IConsentHistoryProps> = props => {
  const renderOptIn = (optIn?: boolean) => {
    return optIn ? "Yes" : "No";
  };

  const buildDescriptionItems = (consentHistoryItem: IConsent): IConsentHistoryItem[] => {
    return [
      {
        label: "Source",
        text: consentHistoryItem.updatedBy
      },
      {
        label: "Timestamp",
        text: formatDateTimeString(consentHistoryItem.timestamp, "-")
      },
      {
        label: "MDM ID",
        text: props.mdmId ? props.mdmId : "-"
      },
      {
        label: "OptIn",
        text: renderOptIn(consentHistoryItem.optIn || false)
      },
      {
        label: "Reason",
        text: consentHistoryItem.reason ? consentHistoryItem.reason : "-"
      },
      {
        label: "Change reason type",
        text: consentHistoryItem.changeReasonType ? consentHistoryItem.changeReasonType : "-"
      },
      {
        label: "Change reason document",
        text: consentHistoryItem.changeReasonDocument ? consentHistoryItem.changeReasonDocument : "-"
      },
      {
        label: "Agent ID",
        text: consentHistoryItem.agentId ? consentHistoryItem.agentId : "-"
      },
      {
        label: "Comment",
        text: consentHistoryItem.comment ? consentHistoryItem.comment : "-"
      }
    ];
  };

  const collapseItems = props.consent.history.map(consentItem => {
    const optIn = renderOptIn(consentItem.optIn ?? false);
    const items = buildDescriptionItems(consentItem);
    const title = `${formatDateTimeString(consentItem.timestamp)} - ${optIn} - ${consentItem.updatedBy}`;

    return {
      key: consentItem.id,
      label: title,
      children: (
        <div className="ConsentHistoryPopup__Items">
          {items.map((item, index) => {
            return (
              <React.Fragment key={`${consentItem.id}_${index}`}>
                <div className="ConsentHistoryPopup__ItemDescription">
                  {item.label}
                </div>
                <div>
                  {item.text}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )
    };
  });

  const popupHeaderTitle = `Consent History - ${props.consent.title} - ${props.consent.channelValue}`;

  return (
    <Drawer title={popupHeaderTitle} open={props.open} onClose={props.onClose} size="large">
      <Collapse accordion items={collapseItems} bordered={false} defaultActiveKey={["0"]} />
    </Drawer>
  );
};

export default ConsentHistoryPopup;
