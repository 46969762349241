import { AxiosRequestConfig } from "axios";
import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { IApiResponseRaw } from "@ap/interfaces";
import { API_URL } from "../apiUrl";

export interface IUpdateUserRequest {
  params: {
    uid: string;
  };
  data: ISapAccount;
}

export interface IUpdateUserResponse extends IApiResponseRaw {
}

export const updateUser = ({ params, data }: IUpdateUserRequest): AxiosRequestConfig => {
  return {
    url: API_URL.USER_UPDATE,
    params: params,
    method: "POST",
    data: data
  };
};
