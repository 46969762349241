import { useContext } from "react";
import { AppContext } from "@ap/store";

export const usePermissions = () => {
  const appContext = useContext(AppContext);

  const permissions = appContext.activeFlow?.permissions ?? [];

  const hasPermission = (permissionKey: string) => {
    return permissions.some(permission => permission === permissionKey);
  };

  return {
    hasPermission
  };
};
