import React, { useCallback, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { message, Table, TableProps } from "antd";
import type { SortOrder, SorterResult } from "antd/es/table/interface";
import type { GetComponentProps } from "rc-table/lib/interface";
import { IUser, IBackUrlState } from "@ap/interfaces";
import { routes } from "@ap/common/routes";
import { useFetch } from "@ap/hooks";
import * as api from "@ap/services/api";
import Loader from "@ap/components/Loader";
import { AppContext } from "@ap/store";
import UsersTableHeader from "../UsersTableHeader";
import { mapAccountsToUsers, getHistoryState, replaceHistoryState } from "./UsersTable.utils";
import "./UsersTable.scss";

const { Column } = Table;

type KnownFieldId = "name" | "email" | "dateCreated" | "mdmId";

const mapSortParams = (field: KnownFieldId, order: SortOrder) => {
  // should be mapped on server side
  const fieldMapping: Record<string, string[]> = {
    name: ["data.search.firstName", "data.search.middleName", "data.search.lastName"],
    email: ["data.search.email"],
    dateCreated: ["created"],
    mdmId: ["data.mdmId"]
  };
  const orderMapping = {
    ascend: "",
    descend: "desc"
  };
  order = order ?? "ascend";
  const apiOrder = orderMapping[order];

  return fieldMapping[field].map(item => apiOrder ? `${item} ${apiOrder}` : item).join(", ");
};

const searchStartPage = 0;
const searchPageSize = 100;
const tableStartPage = 1;
const tablePageSize = 10;

const UsersTable: React.FC = () => {
  const historyState = getHistoryState({
    searchStartPage,
    tableStartPage
  });

  const appContext = useContext(AppContext);
  const countryCode = appContext.activeFlow?.id ?? "";
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [searchCountryCode, setSearchCountryCode] = useState<string>(countryCode);
  const [searchText, setSearchText] = useState<string>(historyState.searchText);
  const [sortBy, setSortBy] = useState<string>(historyState.sortBy);
  const [searchPage, setSearchPage] = useState<number>(historyState.searchPage);
  const [tableCurrentPage, setTableCurrentPage] = useState<number>(historyState.tableCurrentPage);
  const [users, setUsers] = useState<IUser[] | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const getUsers = useFetch<api.IGetUsersRequest>(api.getUsers);
  const [getUsersError, setGetUsersError] = useState<string>("");

  const tableTotalPages = Math.ceil(users.length / tablePageSize);

  const updateSearchParams = () => {
    replaceHistoryState({
      searchText,
      sortBy,
      searchPage,
      searchStartPage,
      tableCurrentPage,
      tableStartPage
    });
  };

  const fetchUsersList = async(request: api.IGetUsersRequest) => {
    setLoading(true);

    const handleError = (error: unknown) => {
      console.error("Unable to load users:", error);
      setGetUsersError(`${error}`);
    };

    try {
      const response = await getUsers<api.IGetUsersResponse>(request);
      const newUsers = mapAccountsToUsers(response.data);

      if (searchPage !== searchStartPage) {
        setUsers([
          ...users,
          ...newUsers
        ]);
      } else {
        setUsers(newUsers);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (getUsersError) {
      messageApi.open({
        type: "error",
        content: `User search failed: ${getUsersError}`
      }).then(() => {
        setGetUsersError("");
      });
    }
  }, [getUsersError]);

  useEffect(() => {
    setSearchCountryCode(countryCode);

    if (countryCode !== searchCountryCode) {
      setSearchPage(searchStartPage);
      setTableCurrentPage(tableStartPage);
    }
  }, [countryCode]);

  useEffect(() => {
    updateSearchParams();
  }, [tableCurrentPage]);

  useEffect(() => {
    updateSearchParams();

    let requestSearchPage = searchPage;
    let requestSearchPageSize = searchPageSize;

    if (users.length === 0 && searchPage > searchStartPage) {
      requestSearchPage = searchStartPage;
      requestSearchPageSize = searchPageSize * (searchPage + 1);
    }

    void fetchUsersList({
      data: {
        countryCode: searchCountryCode,
        page: requestSearchPage,
        pageSize: requestSearchPageSize,
        sortBy: sortBy || undefined,
        filter: searchText ? {
          quickSearch: {
            regex: searchText.toLowerCase().split(/\s+/)
          }
        } : undefined
      }
    });
  }, [searchCountryCode, searchText, sortBy, searchPage]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    setSearchPage(searchStartPage);
    setTableCurrentPage(tableStartPage);
  };

  const handleTableChange = useCallback<Exclude<TableProps["onChange"], undefined>>(
    ({ current }, filters, sorter, { action }) => {
      if (action === "paginate") {
        setTableCurrentPage(current as number);

        if (current && current === tableTotalPages) {
          setSearchPage(Math.ceil((tablePageSize * current) / searchPageSize));
        }
      } else if (action === "sort") {
        const { field, order } = sorter as SorterResult<IUser>;
        const sortBy = mapSortParams(field as KnownFieldId, order ?? null);

        setSortBy(sortBy);
        setSearchPage(searchStartPage);
        setTableCurrentPage(tableStartPage);
      }
    },
    [tableTotalPages, sortBy]
  );

  const handleTableRowClick = useCallback<GetComponentProps<IUser>>(
    (user: IUser) => ({
      onClick: () => {
        const state: IBackUrlState = {
          backUrl: `${window.location.pathname}${window.location.search}`
        };

        navigate(`/${routes.users}/${user.id}`, { state });
      }
    }),
    []
  );

  if (loading) {
    return (
      <Loader>
        Loading users ...
      </Loader>
    );
  }

  const renderTableHeader = () => (
    <UsersTableHeader
      searchText={searchText}
      onSearch={handleSearch}
    />
  );

  return (
    <div className="table__container">
      {contextHolder}

      <Table
        dataSource={users}
        rowKey="id"
        loading={loading}
        bordered={true}
        sticky={true}
        showHeader={true}
        title={renderTableHeader}
        pagination={{ current: tableCurrentPage, pageSize: tablePageSize, showSizeChanger: false }}
        sortDirections={["ascend", "descend"]}
        onChange={handleTableChange}
        onRow={handleTableRowClick}
      >
        <Column title="Display Name" dataIndex="name" key="name" sorter={true} />
        <Column title="Email Address" dataIndex="email" key="email" sorter={true} />
        <Column title="Registration Date" dataIndex="dateCreated" key="dateCreated" width={"11rem"} sorter={true} />
        <Column title="MDM ID" dataIndex="mdmId" key="mdmId" width={"7rem"} sorter={true} />
      </Table>
    </div>
  );
};

export default UsersTable;
