import { useEffect, useState } from "react";
import { useParams, Navigate, generatePath } from "react-router-dom";
import { Result } from "antd";
import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { UserEdit } from "@ap/components";
import * as api from "@ap/services/api";
import Loader from "@ap/components/Loader";
import { useFetch, usePermissions } from "@ap/hooks";
import { permissions } from "@ap/constants";
import { routes } from "@ap/common/routes";

const UserEditPage = () => {
  const { uid } = useParams();
  const [user, setUser] = useState<ISapAccount | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const getUser = useFetch<api.IGetUserRequest>(api.getUser);

  const { hasPermission } = usePermissions();
  const isProfileUpdatePermission = hasPermission(permissions.PROFILE_UPDATE);

  const fetchUser = async(request: api.IGetUserRequest) => {
    setLoading(true);

    const handleError = (error: unknown) => {
      console.error("Unable to load user:", error);
    };

    try {
      const response = await getUser<api.IGetUserResponse>(request);
      const user = response.data;

      setUser(user);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (uid) {
      void fetchUser({
        params: {
          uid
        }
      });
    }
  }, [uid]);

  if (!uid) {
    return (
      <Navigate to={`/${routes.users}`} />
    );
  }

  if (!isProfileUpdatePermission) {
    const redirectUrl = generatePath(`/${routes.users}/:id`, { id: uid });

    return (
      <Navigate to={redirectUrl} />
    );
  }

  if (loading) {
    return (
      <Loader>
        Loading user ...
      </Loader>
    );
  }

  if (!user) {
    return (
      <Result
        status="error"
        title="Unable to load user"
      />
    );
  }

  return (
    <UserEdit user={user} />
  );
};

export default UserEditPage;
