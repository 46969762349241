import React from "react";
import { Typography } from "antd";
import { ILocalConsent } from "@ap/interfaces";
import "./ConsentNotFound.scss";

const { Text } = Typography;

interface IConsentNotFoundProps extends React.PropsWithChildren {
  consent: ILocalConsent;
}

const CONSENT_NOT_FOUND_START = "Impossible to capture consent: no channel value is found in user profile, please specify the value in";
const CONSENT_NOT_FOUND_END = "field and try again.";

const ConsentNotFound: React.FC<IConsentNotFoundProps> = props => {
  const { consent } = props;

  return (
    <div className="ConsentNotFound">
      <div>
        <Text>
          {CONSENT_NOT_FOUND_START} <span>{consent.channelType}</span> {CONSENT_NOT_FOUND_END}
        </Text>
        {props.children}
      </div>
    </div>
  );
};

export default ConsentNotFound;
