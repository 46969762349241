import { AxiosRequestConfig } from "axios";
import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { API_URL } from "../apiUrl";

export interface IGetUserRequest {
  params: {
    uid: string;
  };
}

export interface IGetUserResponse {
  data: ISapAccount;
}

export const getUser = ({ params }: IGetUserRequest): AxiosRequestConfig => {
  return {
    url: API_URL.USER_DETAILS,
    params: params,
    method: "GET"
  };
};
