import { IOptionItem } from "@ap/interfaces";

export const titles: IOptionItem[] = [
  { value: "", text: "not specified" },
  { text: "Dipl.-Med." },
  { text: "Dr. med." },
  { text: "Dr. med. habil." },
  { text: "Dr. rer. nat." },
  { text: "Dr. rer. nat. habil." },
  { text: "PD Dr. med." },
  { text: "Prof. Dr. med." },
  { text: "Prof. Dr. med. nat." }
];
