import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header as MsdHeader } from "@mds/merck-design-system";
import { Menu, Avatar, Space, Typography, MenuProps, Dropdown, message } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { AppContext } from "@ap/store";
import { storageKeys } from "@ap/constants";
import { routes } from "@ap/common/routes";
import { getFullName } from "@ap/utils";
import { useAuth } from "@ap/hooks";
import "./Header.scss";

type Tab = "users" | "agents";

const items: MenuProps["items"] = [
  {
    label: "Users",
    key: routes.users
  }
];

const Header: React.FC = () => {
  const { logout } = useAuth();
  const appContext = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState<Tab>(routes.users as Tab);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const { permissions, activeFlow } = appContext;

  if (!permissions || !activeFlow || permissions.availableFlows.length === 0) {
    return null;
  }

  const { availableFlows, familyName, givenName } = permissions;
  const fullName = getFullName([givenName, familyName]);

  const countries = availableFlows.map(availableFlow => ({
    key: availableFlow.id,
    label: availableFlow.title,
    icon: (
      <Avatar className="ap-header__country-avatar" size="small">
        {availableFlow.slug.toUpperCase()}
      </Avatar>
    ),
    value: availableFlow
  }));

  const accountMenu = [
    {
      key: "1",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        logout();
      }
    }
  ];

  const handleCountryClick: MenuProps["onClick"] = ({ key }) => {
    const activeFlow = availableFlows.find(availableFlow => availableFlow.id === String(key));

    if (!activeFlow) {
      void messageApi.open({
        type: "error",
        content: "Unable to select active flow"
      });
      return;
    }

    appContext.setActiveFlow(activeFlow);
    localStorage.setItem(storageKeys.SELECTED_FLOW_ID, activeFlow.id);
  };

  const onTabClick: MenuProps["onClick"] = ({ key }) => {
    setSelectedTab(key as Tab);
    navigate(key);
  };

  return (
    <MsdHeader title="Admin Portal">
      {contextHolder}

      <Menu
        className="ap-header__menu"
        onClick={onTabClick}
        selectedKeys={[selectedTab]}
        mode="horizontal"
        items={items}
      />
      <Space size="large" className="ap-header">
        <Space>
          <Dropdown
            menu={{
              items: countries,
              selectedKeys: [activeFlow.id],
              onClick: handleCountryClick
            }}
            disabled={countries.length === 1}
          >
            <div className="ap-header__dropdown-preview">
              <Avatar className="ap-header__avatar">{activeFlow.slug}</Avatar>
              <Typography>{activeFlow.title}</Typography>
            </div>
          </Dropdown>
        </Space>
        <Space>
          <Dropdown menu={{ items: accountMenu }}>
            <Avatar className="ap-header__avatar">
              <UserOutlined />
            </Avatar>
          </Dropdown>
          {fullName && (
            <Typography>{fullName}</Typography>
          )}
        </Space>
      </Space>
    </MsdHeader>
  );
};

export default Header;
