export const locale = Intl.DateTimeFormat().resolvedOptions().locale;

const yearFormatter = new Intl.DateTimeFormat(locale, {
  year: "numeric"
});
const monthFormatter = new Intl.DateTimeFormat(locale, {
  month: "2-digit"
});
const dayFormatter = new Intl.DateTimeFormat(locale, {
  day: "2-digit"
});
const timeFormatter = new Intl.DateTimeFormat(locale, {
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
});

type TDate = Date | string | null | undefined;

export const formatDateString = (date: TDate, defaultValue = "") => {
  if (!date) {
    return defaultValue;
  }

  const value = new Date(date);
  const year = yearFormatter.format(value);
  const month = monthFormatter.format(value);
  const day = dayFormatter.format(value);

  return `${year}-${month}-${day}`;
};

export const formatTimeString = (date: TDate, defaultValue = "") => {
  if (!date) {
    return defaultValue;
  }

  return timeFormatter.format(new Date(date));
};

export const formatDateTimeString = (date: TDate, defaultValue = "") => {
  const formattedDate = formatDateString(date);
  const formattedTime = formatTimeString(date);

  const formattedValue = [formattedDate, formattedTime].filter(value => value).join(" ");

  if (!formattedValue) {
    return defaultValue;
  }

  return formattedValue;
};
