import React, { useState } from "react";
import { ILocalConsent } from "@ap/interfaces";
import { useUser } from "../useUser";
import ConsentHistoryPopup from "./ConsentHistoryPopup";
import ConsentUpdatePopup from "./ConsentUpdatePopup";
import ConsentSection from "@ap/components/User/UserConsent/ConsentSection";
import "./UserConsent.scss";
import { getConsentsByChannelType } from "@ap/components/User/UserConsent/UserConsent.utils";

const UserConsent: React.FC = () => {
  const { user, userConsents, onConsentUpdated } = useUser();
  const [selectedConsent, setSelectedConsent] = useState<ILocalConsent | null>(null);
  const [isConsentHistoryPopupOpen, setIsConsentHistoryPopupOpen] = useState(false);
  const [isConsentUpdatePopupOpen, setIsConsentUpdatePopupOpen] = useState(false);

  const handleConsentHistoryPopupClose = () => {
    setIsConsentHistoryPopupOpen(false);
  };

  const handleConsentUpdatePopupClose = () => {
    setIsConsentUpdatePopupOpen(false);
  };

  const handleConsentUpdateClick = (consent: ILocalConsent) => () => {
    setSelectedConsent(consent);
    setIsConsentUpdatePopupOpen(true);
  };

  const handleConsentHistoryClick = (consent: ILocalConsent) => () => {
    setSelectedConsent(consent);
    setIsConsentHistoryPopupOpen(true);
  };

  const consentsByChannelType = getConsentsByChannelType(userConsents, user);

  return (
    <>
      <div className="consent">
        {!userConsents && (
          <div>
            Impossible to capture consents: no MDMID is found in user profile.<br />
            Please contact support.
          </div>
        )}
        {userConsents && !userConsents?.channels && (
          <div>
            Impossible to capture consents: no allowed channels found in user profile.<br />
            Please contact support.
          </div>
        )}

        {consentsByChannelType?.map(({ title, consents }) => {
          return (
            <ConsentSection
              key={title}
              title={title}
              consents={consents}
              user={user}
              onConsentUpdateClick={handleConsentUpdateClick}
              onConsentHistoryClick={handleConsentHistoryClick}
            />
          );
        })}
      </div>
      {selectedConsent && (
        <>
          <ConsentHistoryPopup
            mdmId={user.profile.mdmId ?? ""}
            consent={selectedConsent}
            open={isConsentHistoryPopupOpen}
            onClose={handleConsentHistoryPopupClose}
          />
          <ConsentUpdatePopup
            mdmId={user.profile.mdmId ?? ""}
            countryCode={user.systemData.countryCode ?? ""}
            consent={selectedConsent}
            isCapture={selectedConsent.isShowCapture ?? false}
            isOpen={isConsentUpdatePopupOpen}
            onClose={handleConsentUpdatePopupClose}
            onConsentUpdated={onConsentUpdated}
          />
        </>
      )}
    </>
  );
};

export default UserConsent;
