export enum ChannelTypes {
  EMAIL = "emailCommunication",
  PHONE = "phone",
  SMS = "sms",
  FAX = "fax",
  POSTAL = "postal",
  TRANSFER_OF_VALUE = "transferOfValue"
}

export type ChannelType =
  ChannelTypes.EMAIL |
  ChannelTypes.PHONE |
  ChannelTypes.SMS |
  ChannelTypes.FAX |
  ChannelTypes.POSTAL |
  ChannelTypes.TRANSFER_OF_VALUE;

export interface IConsent {
  id?: string;
  channelType: ChannelType;
  channelValue?: string;
  optIn?: boolean;
  source?: string;
  sourceId?: string;
  timestamp?: string;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  agentId?: string;
  changeReasonDocument?: string;
  changeReasonType?: string;
  comment?: string;
  consentSource?: string;
  consentSourceKey?: string;
  disclaimer?: string;
  reason?: string;
  referral?: string;
  expirationDate?: string;
}
