import React, { useEffect, useContext, useState } from "react";
import { Result } from "antd";
import { storageKeys } from "@ap/constants";
import { AppContext } from "@ap/store";
import Loader from "@ap/components/Loader";
import * as api from "@ap/services/api";
import { useFetch, useAuth } from "@ap/hooks";
import { hasTokenExpired } from "@ap/utils/hasTokenExpired";

export interface IProtectedRouteProps extends React.PropsWithChildren {
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = props => {
  const { login } = useAuth();
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(!appContext.permissions);
  const getPermissions = useFetch<void>(api.getPermissions);

  const isTokenExpired = hasTokenExpired();

  const fetchPermissions = async() => {
    setLoading(true);

    const handleError = (error: unknown) => {
      console.error("Unable to load permissions:", error);
    };

    try {
      const response = await getPermissions<api.IGetPermissionsResponse>();
      const permissions = response.data;

      appContext.setPermissions(permissions);

      if (permissions.availableFlows.length === 0) {
        handleError("Permissions do not have any available flow");
      }

      const selectedFlowId = localStorage.getItem(storageKeys.SELECTED_FLOW_ID);
      const activeFlow =
        permissions.availableFlows.find(availableFlow => availableFlow.id === selectedFlowId) ||
        permissions.defaultFlow ||
        permissions.availableFlows[0];

      appContext.setActiveFlow(activeFlow);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isTokenExpired) {
      login(true);
    }
  }, [isTokenExpired]);

  useEffect(() => {
    if (!isTokenExpired && !appContext.permissions) {
      void fetchPermissions();
    }
  }, [isTokenExpired, appContext.permissions]);

  if (isTokenExpired) {
    return (
      <Loader>
        Login token expired. Redirecting to login ...
      </Loader>
    );
  }

  if (loading) {
    return (
      <Loader>
        Loading permissions ...
      </Loader>
    );
  }

  if (!appContext.permissions) {
    return (
      <Result
        status="warning"
        title="Unable to load permissions"
      />
    );
  }

  return props.children;
};

export default ProtectedRoute;
