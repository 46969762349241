import { useOutletContext } from "react-router-dom";
import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { IConsentsData } from "@ap/interfaces";

export type UserContext = {
  user: ISapAccount;
  userConsents?: IConsentsData;
  onConsentUpdated: () => void;
};

export const useUser = () => {
  return useOutletContext<UserContext>();
};
