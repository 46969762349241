import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "@ap/App";
import { routes } from "@ap/common/routes";
import { MainLayout, ProtectedRoute, UserConsent, UserDetails } from "@ap/components";
import { LogoutPage, NotFoundPage, UsersPage, UserPage, LoginStatusPage, UserEditPage } from "@ap/pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Navigate to={`/${routes.users}`} replace />
      },
      {
        element: (
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: routes.users,
            element: <UsersPage />
          },
          {
            path: `/${routes.users}/:uid`,
            element: <UserPage />,
            children: [
              {
                path: routes.userDetails.consent,
                element: <UserConsent />
              },
              {
                path: "",
                element: <UserDetails />
              }
            ]
          },
          {
            path: `/${routes.users}/:uid/${routes.userDetails.edit}`,
            element: <UserEditPage />
          }
        ]
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: routes.loginStatus,
            element: <LoginStatusPage />
          }
        ]
      },
      {
        path: routes.logout,
        element: <LogoutPage />
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: "*",
            element: <NotFoundPage />
          }
        ]
      }
    ]
  }
]);
