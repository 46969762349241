import { Typography } from "antd";
import "./NotFoundPage.scss";

function NotFoundPage() {
  return (
    <div className="not__found">
      <Typography.Title className="not__found__title" >404</Typography.Title>
      <Typography.Paragraph className="not__found__description">Sorry, page not found</Typography.Paragraph>
    </div>
  );
}

export default NotFoundPage;
