import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { IUser } from "@ap/interfaces";
import { formatDateTimeString, getFullName } from "@ap/utils";

export const mapAccountsToUsers = (accounts: ISapAccount[]): IUser[] => {
  return accounts.map(user => {
    const { mdmId, email, uid } = user.profile;

    return {
      id: uid,
      mdmId: mdmId ?? "-",
      email,
      dateCreated: formatDateTimeString(user.systemData.created, "-"),
      name: getFullName([user.profile.firstName, user.profile.middleName, user.profile.lastName])
    } as IUser;
  });
};

interface ISearchArgs {
  searchText: string;
  sortBy: string;
  searchPage: number;
  searchStartPage: number;
  tableCurrentPage: number;
  tableStartPage: number;
}

type ISearchDefaultArgs = Pick<ISearchArgs, "searchStartPage" | "tableStartPage">;
type ISearchInitialArgs = Omit<ISearchArgs, "searchStartPage" | "tableStartPage">;

const SEARCH_PARAM = "search";
const SORT_BY_PARAM = "sortBy";
const PAGE_PARAM = "page";
const CURRENT_PAGE_PARAM = "currentPage";

export const getHistoryState = (searchArgs: ISearchDefaultArgs): ISearchInitialArgs => {
  const searchParams = new URLSearchParams(location.search);

  const searchPage = searchParams.get(PAGE_PARAM);
  const tableCurrentPage = searchParams.get(CURRENT_PAGE_PARAM);

  return {
    searchText: searchParams.get(SEARCH_PARAM) ?? "",
    sortBy: searchParams.get(SORT_BY_PARAM) ?? "",
    searchPage: searchPage ? parseInt(searchPage) : searchArgs.searchStartPage,
    tableCurrentPage: tableCurrentPage ? parseInt(tableCurrentPage) : searchArgs.tableStartPage
  };
};

export const replaceHistoryState = (searchArgs: ISearchArgs) => {
  const searchParams = new URLSearchParams(location.search);

  if (searchArgs.searchText) {
    searchParams.set(SEARCH_PARAM, searchArgs.searchText);
  } else {
    searchParams.delete(SEARCH_PARAM);
  }

  if (searchArgs.sortBy) {
    searchParams.set(SORT_BY_PARAM, searchArgs.sortBy);
  } else {
    searchParams.delete(SORT_BY_PARAM);
  }

  if (searchArgs.searchPage !== searchArgs.searchStartPage) {
    searchParams.set(PAGE_PARAM, String(searchArgs.searchPage));
  } else {
    searchParams.delete(PAGE_PARAM);
  }

  if (searchArgs.tableCurrentPage !== searchArgs.tableStartPage) {
    searchParams.set(CURRENT_PAGE_PARAM, String(searchArgs.tableCurrentPage));
  } else {
    searchParams.delete(CURRENT_PAGE_PARAM);
  }

  let searchParamsString = searchParams.toString();

  if (searchParamsString) {
    searchParamsString = "?" + searchParamsString;
  }

  const href = `${location.origin}${location.pathname}${searchParamsString}`;

  window.history.replaceState({}, "", href);
};
